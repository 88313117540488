import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import drop from "../../../assets/images/drop.png";
import styles from "../../chineseFormDetails/styles/style.module.css";
import { getCountries } from "../../basicdetail/api";
import { LANGUAGE } from "../../../utils/constants";

function PlanDetails({ setGetPlanDetails, setDataPack, setSelectLan, selectLan }: any) {
  const [selectedDataPack, setSelectedDataPack] = useState<number>(1);
  const [dataPackError, setDataPackError] = useState<string>("");
  const [countDropDown, setCountDropDown] = useState<boolean>(false);
  const [countriesList, setCountriesList] = useState<string[]>([]);

  const countriesInEnglish: string[] = [
    "Aland Island", "Guadeloupe", "Panama", "Australia", "Guatemala", "Peru", "Austria",
    "Guernsey", "Poland", "Azores", "Hongkong", "Portugal", "Belgium", "Balearic Islands",
    "Hungary", "Puerto Rico", "Iceland", "Republic of Ireland", "Brazil", "Indonesia",
    "Reunion", "Bulgaria", "Isle of Man", "Romania", "Canary Islands", "Israel",
    "Saint Barthelemy", "Chile", "Italy (inc. Sardinia & Sicily)", "Saint Martin",
    "Colombia", "Jersey", "San Marino", "Costa Rica", "Latvia", "Singapore", "Croatia",
    "Liechtenstein", "Slovakia", "Cyprus", "Lithuania", "Slovenia", "Czech Republic",
    "Luxembourg", "Spain", "Denmark", "Macau", "Sri Lanka", "El Salvador", "Madeira",
    "Sweden", "Estonia", "Malta", "Switzerland", "Falkland Islands", "Marie-Galante",
    "United States (inc. Florida Keys)", "Finland", "Martinique", "Uruguay", "Mayotte",
    "US Virgin Islands", "French Guiana", "Netherlands", "Vatican City", "Germany",
    "Vietnam", "New Zealand", "Gibraltar", "Nicaragua", "Greece (inc. Crete & Rhodes)",
    "Norway"
  ];
  const countriesInChinese: string[] = [
    "瓜德罗普岛", "巴拿马", "澳大利亚", "危地马拉", "秘鲁", "奥地利", "根西岛",
    "波兰", "亚速尔群岛", "香港", "葡萄牙", "比利时", "巴利阿里群岛", "匈牙利",
    "波多黎各", "冰岛", "爱尔兰共和国", "巴西", "印尼", "留尼旺岛", "保加利亚",
    "马恩岛", "罗马尼亚", "加那利群岛", "以色列", "圣巴泰勒米岛", "意大利",
    "圣马丁", "哥伦比亚", "泽西岛", "哥斯达黎加", "拉脱维亚", "新加坡", "克罗地亚",
    "列支敦士登", "斯洛伐克", "塞浦路斯", "立陶宛", "斯洛文尼亚", "捷克共和国",
    "卢森堡", "西班牙", "丹麦", "澳门", "斯里兰卡", "萨尔瓦多", "马德拉岛", "瑞典",
    "爱沙尼亚", "马耳他", "瑞士", "福克兰群岛", "玛丽-加朗特岛", "美国", "芬兰",
    "马提尼克岛", "乌拉圭", "马约特岛", "美国维珍岛", "法属圭亚那", "荷兰",
    "梵蒂冈城", "德国", "越南", "新西兰", "直布罗陀", "尼加拉瓜", "希腊（克里特岛和罗兹岛）",
    "挪威"
  ];

  let title, data, isFree, localCall, containt, selectCounty, viewAll, btn, days, lyca;
  if (selectLan == LANGUAGE.CHINESE) {
    title = "套餐详情";
    data = "流量数据";
    isFree = "3英國";
    localCall = "3000分钟本地通话";
    containt = "在访问国";
    selectCounty = "覆盖 70+ 个国家"
    viewAll = "查看全部";
    btn = "下一步";
    days="30天";
    lyca="英國萊卡"
  } else if (selectLan == LANGUAGE.ENGLISH) {
    title = "Plan Details"
    data = "Data";
    isFree = "3UK";
    localCall = "3000 Local Calls";
    containt = "in the visiting country";
    selectCounty = "70+ Reached Countries";
    viewAll = "View All";
    btn = "Next";
    days ="30 Days";
    lyca = "UK Lyca"
  }

  useEffect(() => {
    if (selectLan == LANGUAGE.CHINESE) {
      setCountriesList(countriesInChinese)
    } else if (selectLan == LANGUAGE.ENGLISH) {
      setCountriesList(countriesInEnglish)
    }
  }, [selectLan]);

  const handleDataPack = (e: any) => {
    const value = e.target.value;
    setSelectedDataPack(value);
    setDataPackError("");
  };

  const handleSubmitPackage = () => {
    if (selectedDataPack <= 0) {
      setDataPackError("Please select one Data Package!");
      return false;
    }
    setDataPack(selectedDataPack);
    setGetPlanDetails(false);
  };
  const display = countDropDown ? "block" : "none";
  return (
    <div className={`${styles.bassoDtailFoem} ${styles.plnBnr}`}>
      <div className={styles.planOuter}>
        <h3>{title}<div className="switch">
          <input
            id="language-toggle"
            onChange={(e) => {
              const value = e.target.checked;
              if (!value) setSelectLan(1);
              if (value) setSelectLan(2);
            }}
            className="check-toggle check-toggle-round-flat"
            type="checkbox" checked={selectLan === 2}
          />
          <label htmlFor="language-toggle"></label>
          <span className="on">CH</span>
          <span className="off">EN</span>
        </div> </h3>
        <ul className={styles.dataPack}>
          <li>
            <label className="radiobt">
              <input
                type="radio"
                name="datapack"
                value={1}
                checked={selectedDataPack == 1}
                onChange={handleDataPack}
              />
              <span className="checkmark"></span>
            </label>
            <h5>
              {data}
              <br />
              <b>6 GB</b>
            </h5>
            <h6>{isFree}</h6>
            <p>
              <b>{localCall}</b>
              {/* <br />
              {containt} */}
            </p>
          </li>
          <li>
            <label className="radiobt">
              <input
                type="radio"
                name="datapack"
                value={2}
                checked={selectedDataPack == 2}
                onChange={handleDataPack}
              />
              <span className="checkmark"></span>
            </label>
            <h5>{data}<br />
              <b>10 GB</b></h5>
            <h6>{lyca}</h6>
            <p><b>{days}</b></p>
          </li>
        </ul>
        <div className={styles.listType}>
          <div className={styles.listGrop}>
            <h6>{selectCounty}</h6>
            <span onClick={() => setCountDropDown(!countDropDown)}>
              {viewAll}<img src={drop} alt="" />
            </span>
          </div>
          <ul style={{ display }}>
            {countriesList && countriesList.map((list: any) => <li>{list}</li>)}
          </ul>
        </div>
        {dataPackError && <div className={styles.error}>{dataPackError}</div>}
        <button onClick={handleSubmitPackage}>{btn}</button>
      </div>
    </div>
  );
}

export default PlanDetails;
