import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/style.module.css';
import frdoen from '../../../assets/images/frdoen.png';
import comLogo from "../../../assets/images/comLogo.svg"
import calndr from '../../../assets/images/calndr.png';
import { BasicDetailSchema } from '../validation';
import { useFormik } from 'formik';
import MainLoader from '../../mainLoader';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { addBasicDetails, addDocuments, getCountries, sendOtp, verifyOtp } from '../api';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import useAuth from '../../../lib/useAuth';
import Congratulations from '../../modal/congratulation';
import VerifyOtpModal from '../../modal/Verifition.tsx';
import { validateEmail } from '../../../lib/emailValidation';


const BasicDetails: React.FC = () => {
  const [countriesList, setCountriesList] = useState([])
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loader, setLoader] = useState(false)
  const [otpId, setOTPID] = useState('');
  const [show, setShow] = useState("")
  const [timer, setTimer] = useState(0);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [verifOTPId, setVerifOTPId] = useState('');
  const { loginUser, logout } = useAuth()
  const resendOtpHandle = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    getCountries().then((res) => {
      setCountriesList(res?.data?.countries)
    }).catch((error) => console.log(error))
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      travellingCountry: "",
      travellingDate: "",
      handsetModelNo: "",
    },
    validationSchema: BasicDetailSchema,
    onSubmit: async (values, { setFieldError }) => {
      setLoader(true);
      if (!isEmailVerified) {
        setFieldError('email', 'email is not verified!');
        setLoader(false)
        return
      }
      const parsedNumber = parsePhoneNumber(values.phoneNumber);
      let BasicPayload: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        countryCode: `+${parsedNumber?.countryCallingCode as string}`,
        phoneNumber: parsedNumber?.nationalNumber as string,
        travellingCountry: values.travellingCountry,
        travellingDate: values.travellingDate,
        handsetModelNo: values.handsetModelNo,
        otpId: verifOTPId,
        registrationType: 2
      };
      // toast.promise(
      //   addBasicDetails(BasicPayload),
      //   {
      //     pending: {
      //       render() {
      //         return 'Trying to save your informations...';
      //       }
      //     },
      //     success: {
      //       render({ data }: any) {
      //         loginUser(data?.data?.accessToken)
      //         addDocuments({userPartnerInfoId : data?.data?.userPartnerInfoId}).then((res)=> {
      //           setShowModal(true)
      //           setLoader(false)
      //           formik.resetForm()
      //         }).catch((error)=> {
      //           setLoader(false)
      //           return "Something  went wrong!";
               
      //         })
      //         return "Your informations Successful saved";
      //       }
      //     },
      //     error: {
      //       render({ data }: any) {
      //         setLoader(false)
      //         return data?.data?.message || "Something went wrong!";
      //       }
      //     }
      //   });
      toast.promise(
        addBasicDetails(BasicPayload),
        {
          pending: {
            render() {
              return 'Trying to save your information...';
            }
          },
          success: {
            render({ data }: any) {
              loginUser(data?.data?.accessToken);
      
              // Call handleAddDocuments and pass the userPartnerInfoId
              handleAddDocuments(data?.data?.userPartnerInfoId);
      
              return "Your information was successfully saved";
            }
          },
          error: {
            render({ data }: any) {
              setLoader(false);
              return data?.data?.message || "Something went wrong!";
            }
          }
        }
      );
    },
  });


  const handleAddDocuments = (userPartnerInfoId: string) => {
    addDocuments({ userPartnerInfoId })
      .then((res) => {
        setShowModal(true);
        setLoader(false);
        formik.resetForm();
        toast.success("Your documents were successfully saved");
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.message || "Failed to add documents!");
      });
  };
  const today = new Date().toISOString().split('T')[0];

  const handleSendOtp = () => {
    const email: any = formik.values.email;
    if (validateEmail(email)) {
      formik.setErrors({ ...formik.errors, email: "" });
    } else if (formik.errors.email !== undefined || email === "") {
      return false;
    }
    const otpPayload = {
      email,
      type: 3
    }
    setLoader(true)
    toast.promise(
      sendOtp(otpPayload),
      {
        pending: {
          render() {
            return 'Trying to send OTP';
          }
        },
        success: {
          render({ data }) {
            setOTPID(data.data.otpId);
            setShow("show");
            setTimer(Date.now() + 30000);
            setFieldForVerify("email")
            setLoader(false)
            return 'OTP sent successfully';
          }
        },
        error: {
          render({ data }: any) {
            setLoader(false)
            return data?.data?.message || "Something went wrong!";
          }
        }
      });
  }

  const otpVeriFy = (payload: any) => {
    setLoader(true)
    toast.promise(
      verifyOtp(payload),
      {
        pending: {
          render() {
            return 'Trying to verify OTP';
          }
        },
        success: {
          render({ data }: any) {
            setVerifOTPId(data?.data?.otpId)
            setIsEmailVerified(true)
            setLoader(false)
            setShow("")
            return 'OTP verified!';
          }
        },
        error: {
          render({ data }: any) {
            setLoader(false)
            return "Something went wrong please try again!";
          }
        }
      });
  }

  const [fieldForVerify, setFieldForVerify] = useState('')

  const handleResendOTP = () => {
    if (resendOtpHandle.current) {
      resendOtpHandle.current.click()
    }
  }
  const ClosedModal = (newValue: any) => {
    setShow(newValue);
  };

  return (

    <div className={`${styles.bassoDtailFoem} ${styles.baseBnner}`}>
      {loader && <MainLoader />}
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.stepHeader}>
          {/* <Link to='/'><span>
                    <img src={blackLogo} alt="" />
                  </span></Link>  */}
          <a href='https://commbitz.com/'><span>
            <img src={comLogo} alt="" />
          </span></a>
        </div>

        <h4>Could you please provide<br /> more details about your trip?</h4>
        <div className="row">
          <div className="col-md-6">
            <div className={styles.formGroup}>
              <label>First Name*</label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className={styles.error}>{formik.errors.firstName}</div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.formGroup}>
              <label>Last Name*</label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className={styles.error}>{formik.errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>Phone*</label>
              <PhoneInput
                international
                defaultCountry="GB"
                // countryCodeEditable={true}
                className={styles.phoneNumber}
                placeholder="Enter your phone number here"
                onChange={(value) => {
                  formik.setFieldValue('phoneNumber', value)
                }}
                value={formik.values.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className={styles.error}>{formik.errors.phoneNumber}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>Email* 
               <a ref={resendOtpHandle} onClick={isEmailVerified ? undefined : handleSendOtp}> {isEmailVerified ? "Verified" : "verify"}</a>
               </label>
              <input
                type="text"
                name="email"
                placeholder="Enter your email here"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className={styles.error}>{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>Handset Model Name*</label>
              <input
                type="text"
                name="handsetModelNo"
                placeholder="Enter handset model number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.handsetModelNo}
              />
              {formik.touched.handsetModelNo && formik.errors.handsetModelNo && (
                <div className={styles.error}>{formik.errors.handsetModelNo}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>Travelling Country*</label>
              <select
                name="travellingCountry"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.travellingCountry}
              >
                <option value="">Select Country</option>
                {countriesList && countriesList.map((list: any) => (
                  <option value={list?._id}>{list?.name}</option>
                ))}

              </select>
              <span><img src={frdoen} alt="Dropdown Icon" /></span>
              {formik.touched.travellingCountry && formik.errors.travellingCountry && (
                <div className={styles.error}>{formik.errors.travellingCountry}</div>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>Travelling Date*</label>
              <input
                type="date"
                name="travellingDate"
                placeholder="Select Date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.travellingDate}
                min={today}
              />
              <span><img src={calndr} alt="Calendar Icon" /></span>
              {formik.touched.travellingDate && formik.errors.travellingDate && (
                <div className={styles.error}>{formik.errors.travellingDate}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <input type="submit" value="Submit" />
            </div>
          </div>
        </div>
      </form>
      <VerifyOtpModal
        show={show}
        otpId={otpId}
        timer={timer}
        otpVeriFy={otpVeriFy}
        fieldForVerify={fieldForVerify}
        handleResendOTP={handleResendOTP}
        ClosedModal={ClosedModal}
      />
      <Congratulations show={showModal} setShowModal={setShowModal} />
    </div>

  );
}

export default BasicDetails;
