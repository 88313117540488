import { Navigate, useLocation } from "react-router-dom";
import Document from "../components/document/routes";
import ChinesFormDetails from "../components/chineseFormDetails/routes";
import BasicDetails from "../components/basicdetail/routes";

const hostname = window.location.hostname;

export const publicRoutes = hostname == 'cnsh.commbitz.com' ? [
  { path: "/", element: <ChinesFormDetails /> },
  { path: "*", element: <Navigate replace to="/" /> }
] :
  [{ path: "/", element: <BasicDetails /> },
  { path: "*", element: <Navigate replace to="/" /> }
  ];

// export const publicRoutes = hostname == 'localhost' ? [
//   { path: "/", element: <ChinesFormDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
// ] :
// [  { path: "/", element: <BasicDetails /> },
//   { path: "*", element: <Navigate replace to="/" /> }
// ];
